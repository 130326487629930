.Avatar {
    vertical-align: top;
}
.Social {
    transition: all 0.5s;
    opacity: 0.8;
}
.Social:hover {
    opacity: 1;
}
.Card {
    transition: transform 2s;
    width: 100%
}

.Card:hover {
    transform-origin: center;
    transform: scale(1.15);
}

.Text {
    max-height: 0;
    transition: all 2s;
    opacity: 0;
}

.Card:hover > .Stuff > .Text {
    max-height: 20rem;
    opacity: 1;
}